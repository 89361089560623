.stats-options-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
    align-items: center;
    justify-content: center;
    row-gap: 2rem;
    column-gap: 2rem;
}

.stats-options-controls-container {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 2rem;
}

.stats-option-date-picker-description {
    margin: 0.5rem;
}

.stats-options-button {
    background-color: var(--green);
    color: black;
    height: 2rem;
    width: 3rem;
}

.stats-options-button:active {
    background-color: white;
    color: var(--green);
}

.stats-option-date-picker {
    background: var(--form-white);
    height: 3rem;
    border-radius: var(--border-radius);
}