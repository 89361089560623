
/* CustomCard */
.custom-card-item-location {
    min-width: 22rem;
    flex: 1 0 0;
}

.custom-card-header-location {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    align-content: flex-start;
}

.custom-card-footer-location {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
}

.location-header-summary {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.location-header-controls {
    flex: 0 0 auto
}

.location-header-tooltip-button {
    background-color: transparent;
    color: white;
}

.location-header-spacer {
    margin: 0 0.45rem
}

.location-header-collapse-button {
    background-color: transparent;
    color: white;
}

.location-header-collapse-button:disabled {
    color: gray;
}

.location-machines-container-wrapper {
    position: relative;
    min-height: 160px;
}

.location-machines-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    margin: 1.25rem;
}

.location-activity-wrapper {
    position: absolute;
    top:0;
    right: 0;
    bottom: 0;
    left: 0;
    min-height: 160px;

    transition: opacity 250ms;
}

.collapsing + .location-activity-wrapper,
.show + .location-activity-wrapper {
    opacity: 0;
    pointer-events: none;
}

.location-activity-text-wrapper {
    position: absolute;
    width: 100%;
    height: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
}

.location-activity-text {
    background: rgba(0, 0, 0, 0.5);
    font-family: Roboto, sans-serif;
    border-radius: 1rem;
    padding: 0.75rem;
    transition: transform 1s;
}