.btn-accordion {
    color: var(--white);
    width: 100%;
    text-align: left;
}

.btn-accordion:hover {
    color: var(--gray);
}

.shadowed {
    pointer-events: none;
    position: absolute;
    height: 100%;
    width: 100%;
    box-shadow: inset 0 0.675rem 0.25rem -0.75rem #000,
    inset 0 -0.675rem 0.25rem -0.75rem #000;
}