.tooltip-machine-image {
    width: 4rem;
    height: 4rem;
}
#print-table .img-wrapper img {
    max-width: 200px;
    max-height: auto;
    /* width: 100px; */
}
@media print {
    /* body *:not(.print-table) {
        display: none !important;
    } */
    thead,
    .sidebar-column,
    #print-button,
    nav {
        display: none !important;
    }
    /* body * {
        visibility: hidden;
    } */

    /* .print-table, */
    .print-table * {
        visibility: visible;
    }
    .category,
    .notes,
    .gameEngine,
    .gameAutoPause,
    .gamePackage,
    .updated,
    .machines,
    footer {
        display: none;
    }

    #print-table .img-wrapper {
        height: auto;
        /* width: 20vw; */
        /* width: 100%; Set a width for the container */
        aspect-ratio: 3 / 4;
        /* overflow: hidden; Hide any content that overflows the container */
    }

    #print-table .img-wrapper img {
        /* width: 200px !important; */

        width: 100%;
        /* Fill the width of the container */
        height: 100%;
        /* Fill the height of the container */
        object-fit: stretch;
        /* Maintain aspect ratio and crop if necessary */
    }

    table th,
    table tr td {
        color: black !important;
        background-color: white;
    }
}
