/*=== Auth page ===*/
.auth-page-layout {
    display: grid;
    width: 100%;
    height: 100%;
    justify-content: center;
}

.auth-page-form {
    max-width: 18.5rem;
}

.auth-page-logo {
    pointer-events: none;
    margin-top: 15%;
}

.auth-page-footer {
    text-align: center;
    margin-top: 4rem;
    color: var(--white);
}

.btn-login {
    background: var(--green) !important;
    color: var(--font-btn-green);
    height: 3.5rem;
    width: 18rem;
    border-radius: 1.25rem;
    border: none var(--white) !important;
    display: flex;
    align-items: center;
    justify-content: center;
    left: 0;
    right: 0;
    margin: 0 auto;
    position: relative;
    box-shadow: none !important;
}

.btn-login:hover {
    background: var(--white) !important;
    color: var(--green) !important;
}

.btn-login:active {
    background: var(--gray) !important;
    color: var(--green) !important;
    border: 0.15rem var(--green) solid !important;
}

.btn-login:focus {
    background: var(--white) !important;
    color: var(--gray) !important;
}