/*=== Machine image ===*/
.machine {
    text-align: center;
    margin: 0.5rem;
    max-width: 6rem;
    user-select: none;
}

.machine-inactive-image {
    filter: grayscale(100%);
}

/* .machine-active-image {
} */

.machine-image-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 6rem;
    height: 6rem;
    margin: auto auto 0.5rem;
    position: relative;
    pointer-events: none;
}

.machine-image-wrapper img {
    max-height: 100%;
}

.machine-game-name {
    margin: 0.25rem;
    height: 1.5rem;
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
}

.machine-long-inactive-text {
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    text-align: center;
    font-family: Roboto, sans-serif;
    top: 20%;
    border-radius: 1rem;
    padding: 0.25rem;
}