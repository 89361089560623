/*=== Sidebar column ===*/
.sidebar-column {
    display: flex;
    flex-grow: 0;
    flex-basis: 14rem;
    padding: 0;
    padding-bottom: 10px !important;
}

.nav-menu {
    background-color: var(--dark);
    color: var(--white) !important;
    border-radius: var(--border-radius);
}

.nav-menu:hover {
    background-color: var(--table-gray-second);
    color: var(--white);
}

.nav-menu:focus {
    background-color: var(--dark);
    color: var(--white);
}

.nav-menu.active {
    background-color: var(--table-gray-first) !important;
    color: var(--white);
}

.admin-tabs {
    margin: 0 1rem;
}
