.custom-card-item {
    color: var(--white);
    background: var(--table-gray-first);
    border-radius: var(--border-radius);
    display: flex;
    flex-flow: column;
}

.custom-card-header {
    flex: 0 1 auto;
    padding: 0.75rem 1.25rem;
    font-family: poppins-semibold, poppins, sans-serif;
}

.custom-card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.custom-card-footer {
    flex: 0 1 auto;
    padding: 0.75rem 1.25rem;
}
