.otp-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    margin: 10px;
    padding: 0;
  }
  
  .otp-button img {
    max-width: 200px;
    height: auto;
  }
  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  .otp-buttons-container {
    margin-top: 20px;
  }
  