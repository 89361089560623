
.service-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100vh;
}

.service-page-header {
    font-size: 3rem;
}

.service-page-header::after {
    content: '\00a0';
    animation: type 0.75s alternate infinite;
}

.service-page-link-container a {
    color: var(--green) !important;
}

.service-page-link-container a:hover {
    color: var(--white) !important;;
}

@keyframes type {
    from {
        box-shadow: inset 0.25rem 0 0 #888;
    }
    to {
        box-shadow: inset 0.25rem 0 0 transparent;
    }
}

.service-page-go-back {
    padding-top: 2rem;
}