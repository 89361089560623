.stats-wrapper {
	column-gap: 2rem;
	row-gap: 2rem;
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	align-items: baseline;
}

.charts-grid {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: repeat(4, max-content);
	gap: 2rem 2rem;
	grid-template-areas:
		'chart0 chart1'
		'chart2 chart3'
		'chart4 chart4'
		'chart5 chart5';
}

@media (max-width: 48rem) {
	.charts-grid {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: repeat(4, max-content) repeat(2, min-content);
		gap: 2rem 2rem;
		grid-template-areas:
			'chart0'
			'chart1'
			'chart2'
			'chart3'
			'chart4'
			'chart5';
	}
}

.chart0 {
	grid-area: chart0;
}
.chart1 {
	grid-area: chart1;
}
.chart2 {
	grid-area: chart2;
}
.chart3 {
	grid-area: chart3;
}
.chart4 {
	grid-area: chart4;
}
.chart5 {
	grid-area: chart5;
}

.custom-card-item-chart {
	height: 100%;
}

.chart-wrapper {
	overflow: hidden;
	min-width: 100%;
	min-height: 100%;
}

@media (max-width: 48rem) {
	.custom-card-body-full-height {
		padding: 0.25rem;
	}
}

.chart-note {
	display: flex;
	justify-content: flex-end;
	font-size: 0.7rem;
	align-items: flex-end;
}
