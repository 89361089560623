.loading-indicator-wrapper {
    font-size: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
}

.loading-indicator-wrapper > .spinner-border {
    width: 5rem;
    height: 5rem;
}