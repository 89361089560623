/*=== Zeroing ===*/
*,
*:before,
*:after {
    padding: 0;
    margin: 0;
    border: 0;
    box-sizing: border-box;
}

:root {
    --dark: #1c1c1c;
    --green: #9bdd2d;
    --white: #fff;
    --black: #000000;
    --gray: #c1c1d0;

    --error-red: #d4606b;
    --invalid-red: #ff0000cf;

    --form-white: #e9e9e9;

    --font-btn-green: #374620;

    --table-gray-first: #333333;
    --table-gray-second: #3a3a3a;

    --border-radius: 0.35rem;
    --footer-height: 0.5rem;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    background-color: var(--dark);
    font-family: Roboto, sans-serif;
    color: var(--gray);
    font-size: 1rem;
    line-height: 1.5;
    border: none;

    max-width: 100%;
}

#root {
    min-height: 100% !important;
    position: relative !important;
}
/*=== a ===*/
a {
    color: var(--gray);
}

a:hover {
    text-decoration: none;
    color: var(--white);
    cursor: pointer;
}

ul {
    list-style: none;
    margin-bottom: 0 !important;
}

/*=== Validation ===*/
form {
    position: relative;
}

form > .form-group {
    margin-bottom: 2rem;
}

.form-error-message {
    position: absolute;
    color: var(--error-red);
    border: var(--error-red);
    margin-top: 0.25rem;
    font-size: 0.75rem;
}

.form-control {
    background: var(--form-white);
    height: 3rem;
    border-radius: var(--border-radius);
}

.form-control:focus {
    border-color: var(--green);
    -webkit-box-shadow: none;
    box-shadow: none;
}

.is-invalid {
    border-color: var(--invalid-red) !important;
}

.btn {
    box-shadow: none !important;
}

.alert {
    margin: 2rem 0;
    text-align: center;
    border-radius: var(--border-radius);
    width: 100%;
    /* max-height: 6rem; */
}

.app-label {
    margin: 1rem 0;
    font-family: poppins, sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    text-transform: uppercase;
    color: var(--green);
    text-align: center;
}

.link-painter > a:hover {
    text-decoration: none;
    color: var(--gray);
    cursor: pointer;
}

.link-painter > a {
    color: var(--green);
}

/*=== Navbar ===*/
.navbar {
    background-color: var(--dark);
    color: var(--white) !important;
    padding: 0.5rem 2rem 0 2rem;
}

.nav-link:hover {
    color: var(--white) !important;
}

/*=== Modal ===*/
.modal-header {
    border: none;
}

.modal-content {
    color: var(--black);
    border: none;
}

.modal-footer {
    display: flex;
    justify-content: center;
    border: none;
}

.modal-body > .alert {
    margin-top: 4rem;
}

/*=== Dropdown ===*/
.dropdown-menu {
    background-color: var(--dark);
    color: var(--white);
    border-radius: var(--border-radius);
    z-index: 9999;
    max-width: auto !important;
}
.dropdown-item {
    overflow: visible !important;
    color: var(--white);
}

.dropdown-item.active {
    background-color: var(--table-gray-first);
    color: var(--white);
}

.dropdown-item:hover {
    background-color: var(--table-gray-second);
    color: var(--white) !important;
}

.dropdown-item:focus {
    color: var(--black);
}

/*=== Btn for deleting user ===*/
.btn-delete-user {
    display: inline;
    color: var(--red);
    font-size: 0.8rem;
    margin-left: 0.4rem;
    opacity: 0;
}

.user-link:hover > .btn-delete-user {
    opacity: 1;
}

.btn-transparent {
    color: rgba(255, 255, 255, 0.5);
    padding: 0;
}

.btn-transparent:hover {
    color: var(--white);
}

.btn-transparent:focus {
    outline: none;
    box-shadow: none;
}

/*=== Workaround for total label ===*/
.chart-total
    > .apexcharts-canvas
    > .apexcharts-svg
    > .apexcharts-graphical
    > .apexcharts-bar-series
    > .apexcharts-datalabels:last-child
    > .apexcharts-data-labels
    > text {
    text-anchor: start;
}

.profile-page {
    width: 50%;
    min-width: 20rem;
    margin: auto;
}

.btn-green {
    color: var(--black);
    background-color: var(--green) !important;
    border-color: var(--green) !important;
}

/* Center modal window */
.modal {
    display: flex !important;
    height: 100%;
}

.modal-dialog {
    margin: auto !important;
}

.register-notification {
    font-size: 0.85rem;
    margin: 2rem 0.25rem;
}

.register-notification a {
    color: var(--green);
}

.page {
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.footer {
    text-align: center;
    padding: 1rem;
}

.footer a {
    font-size: 0.85rem;
}

.footer a + a:before {
    content: ' - ';
}

.page-content {
    flex: 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.col {
    flex-basis: content;
}

@media (min-width: 42rem) {
    .page-content {
        margin-left: 2rem;
        margin-right: 2rem;
    }

    .col {
        flex-basis: 0;
    }
}

.dropdown-list-filter-container {
    margin: 0.5rem 1.25rem;
}

.enable-text-overflow {
    overflow: hidden;
    text-overflow: ellipsis;
}

.flex-item-chart {
    flex: 0 1;
}
