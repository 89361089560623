.flex-box-locations {
    column-gap: 2rem;
    row-gap: 2rem;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    align-items: baseline;
}
